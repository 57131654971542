/**
*
* Main footer
*
**/

.l-footer {
    @media all and (min-width: 769px) {
        // position: fixed;
        position: relative;
        z-index: 20;
        // bottom: -$footerHeight;
        // left: 0;
        width: 100%;
        // height: $footerHeight;
        // transform: translateZ(0);
        // will-change: bottom;

        // transition: bottom 0.5s ease;
        
        // .footer-open & {
        //     transition: bottom 0.8s ease;
        //     bottom: 0;
        // }

    }

    // @media all and (min-width: 769px) and (max-width: 1440px) {
    //     bottom: -120px;
    //     height: 120px;
    // }

    &__container {
        width: 100%;
        // padding: 3.5em 2em;
        padding: 1.5em 2em;

        // padding: 4em 2em;

        background-color: #fff;

        @media all and (max-width: 599px) {
            text-align: center;

            .text-right {
                text-align: center;
            }
        }

        @media all and (min-width: 600px) and (max-width: 768px) {
            padding: {
                // top: 2em;
                // bottom: 2em;

                // top: 3em;
                // bottom: 3em;
            }
        }

        @media all and (min-width: 600px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            // padding: {
            //     left: 4em;
            //     right: 4em;
            // }
        }

        // @media all and (min-width: 769px) {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     z-index: 11;

        //     height: $footerHeight;
        // }

        // @media all and (min-width: 769px) and (max-width: 1440px) {
        //     height: 120px;
        //     padding: 0 2em;
        // }

    }



    &__logo {
        transition: opacity 0.2s ease;

        @media all and (max-width: 599px) {
            margin-top: 1rem;
            margin-bottom: 2em;

            img {
                height: 50px;
            }
        }

        &:hover {
            opacity: 0.8;
        }

        img {
            @media all and (min-width: 600px) { // and (max-width: 1919px) {
                height: 50px;
            }
        }
    }

    &__details {
        @media all and (min-width: 768px) {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .separated-items {
            @media all and (min-width: 768px) {
                margin-top: 0;
                margin-right: 5vw;
                order: -1;
            }
        }
    }

    .social-media__link {
        svg {
            fill: $colorMain;
        }
    }
}