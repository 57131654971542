.l-menu {

    &.open {
        visibility: visible;
        opacity: 1;
    }

    &__list {
        opacity: 0;
        text-align: center;
        margin-top: 50px;
        transition: opacity 0.3s, margin-top 0.1s 0.6s;

        .open & {
            opacity: 1;
            margin-top: 0;
            transition: opacity 0.6s, margin-top 0.3s;
        }

        &--submenu-open {
            > .l-menu__item > .l-menu__link {
                opacity: 0;
                visibility: hidden;

                transition: visibility 0s linear 300ms, opacity 300ms;
            }
        }
    }


    &__link {
        position: relative;
        display: inline-block;
        overflow: hidden;

        color: #fff;
        font-family: TTNorms-Black;
        text-transform: uppercase;
        line-height: 1;
        box-sizing: content-box;
        margin: 0;
        background-color: transparent;

        transition: visibility 0s linear 0s, opacity 300ms;

        &:after {
            opacity: 0;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;

            // width: 1000000px;
            width: 100%;

            background: url(/images/menu-wave-fix.svg) center center repeat-x;
            // background: url(/images/wave-gif.gif) center center no-repeat;
            background-size: auto 100% !important;
            transition: opacity 0.4s ease;

            @media (min-width: 1024px) {
                // animation-name: wave;
                // animation-iteration-count:infinite;
                // animation-duration: 5s;
                // animation-timing-function: linear;
                // animation-play-state: paused;

                // animation: wave 5s infinite linear;
                // background: url(/images/wave-gif.gif) center center no-repeat;
                background: url(/images/wave-gif-repet.gif) center center repeat-x;

                background-size: auto 100% !important;
            }
        }

        &:hover {
            &:after {
                opacity: 1;
                // animation-play-state: running;

                @media (min-width: 1024px) {
                    // animation-play-state: running;
                }
            }
        }

        span {
            display: inline-block;
            transition: transform 0.25s ease;

            @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                display: block;
            }

            /*&:hover {
                margin-top: 0.15em;
            }*/
        }

        &--large {
            @include dynamicFontSize(50, 120, 375, 1920);
            padding: 0.15em 0;

            @media (min-width: 1025px) and (max-width: 1399px) {
                padding: 16px 0;
            }

            &:after {
                height: 30px;
                bottom: 0.15em;

                @media (min-width: 3000px) {
                    height: 26px;
                }

                @media (min-width: 1921px) and (max-width: 2999px) {
                    height: 32px;
                }

                @media (min-width: 1400px) and (max-width: 1800px) {
                    height: 24px;
                }

                @media (min-width: 1025px) and (max-width: 1399px) {
                    height: 24px;
                }

                @media (min-width: 769px) and (max-width: 1024px) {
                    height: 15px;
                }

                @media (min-width: 200px) and (max-width: 768px) {
                    height: 13px;
                }

                // @include dynamicHeight(15, 30, 1024, 1920);

                @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                    background-size: 100px 100%;


                    @media (min-width: 1400px) and (max-width: 1800px) {
                        background-size: 70px 100%;
                    }

                    @media (min-width: 1025px) and (max-width: 1399px) {
                        background-size: 50px 100%;
                    }

                    @media (min-width: 200px) and (max-width: 1024px) {
                        background-size: 40px 100%;
                    }
                }
            }

            &:hover span {
                transform: translateY(-20px);

                @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
                    transform: translateY(-10px);
                }

                @media all and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                    transform: translateY(-10px);
                }

                // @media (min-width: 200px) and (max-width: 840px) {
                @media (min-width: 451px) and (max-width: 1024px) {
                    transform: translateY(-10px) !important;
                }

                @media (min-width: 200px) and (max-width: 450px) {
                    transform: translateY(-6px) !important;
                }
            }
        }

        &--medium {
            @include dynamicFontSize(35, 70, 375, 1920);
            padding: 0.3em 0;

            @media (min-width: 200px) and (max-width: 768px) {
                padding: .35em 0 .3em 0;
            }

            &:after {
                height: 22px;
                bottom: 0.2em;

                // @media (min-width: 1801px) {
                //     width: 782.22222px;
                // }

                @media (min-width: 1400px) and (max-width: 1800px) {
                    height: 21px;
                }

                @media (min-width: 1025px) and (max-width: 1399px) {
                    height: 17px;
                }

                @media (min-width: 769px) and (max-width: 1024px) {
                    height: 15px;
                }

                @media (min-width: 200px) and (max-width: 768px) {
                    height: 15px;
                }

                @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                    background-size: 50px 100%;
                }
            }

            &:hover span {
                transform: translateY(-17px);

                @media (min-width: 200px) and (max-width: 840px) {
                    transform: translateY(-13px);
                }
            }
        }

        &--small {
            @include dynamicFontSize(25, 40, 375, 1920);
            padding: 0.35em 0 0.25em 0;

            &:after {
                height: 15px;
                bottom: 0.2em;

                // @include dynamicHeight(9, 15, 1024, 1920);

                @media (min-width: 1400px) and (max-width: 1800px) {
                    height: 13px;
                }

                @media (min-width: 1025px) and (max-width: 1399px) {
                    height: 11px;
                }

                @media (min-width: 769px) and (max-width: 1024px) {
                    height: 11px;
                }

                @media (min-width: 200px) and (max-width: 768px) {
                    height: 9px;
                }

                @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                    background-size: 30px 100%;
                }
            }

            &:hover span {
                transform: translateY(-12px);

                @media (min-width: 200px) and (max-width: 840px) {
                    transform: translateY(-8px);
                }
            }
        }
    }

    &__submenu {
        opacity: 0;
        visibility: hidden;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        width: 100%;
        text-align: center;

        transition: visibility 0s linear 0s, opacity 300ms;


        .l-menu__item--submenu-open & {
            opacity: 1;
            visibility: visible;


            transition: visibility 0.3s linear 300ms, opacity 300ms 0.3s;
        }

        > li {
            &:last-child {
                @media all and (max-width: 768px) {
                    margin-top: 3em;
                }
            }
        }
    }

    &__item {
        @media screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
            position: relative;
            z-index: 1;
            overflow: hidden;
        }

        &--submenu-open {
            overflow: visible;
        }
    }

    &.open {
        > .l-menu__list--submenu-open {
            > .l-menu__item {
                position: static;
            }
        }
    }
}

button.l-menu__link {
    margin-bottom: 5px;
}

@keyframes wave {
    0% {
        left:0;
    }
    100% {
        left:-100%;
    }
}
