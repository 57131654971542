/*

Copyright © David Bushell | @dbushell | http://dbushell.com

*/

@function rem($px, $base: 16) {
    @return ($px / $base) + rem;
}

$color--white     : #fff;
$color--silver    : #f7f7f5;
$color--slate     : #494b4c;
$color--cream     : #f2f0e6;
$color--canvas    : #d9d7ce;
$color--highlight : #fff699;
$color--error     : #e54545;
$color--green     : #99cc66;
$color--blue      : #3d9ccc;
$color--dark-blue : #226181;

$color--grey       : #494b4d;
$color--dark-grey  : #303233;
$color--light-grey : #919599;

$color--line       : lighten(#b3b0aa, 15);
$color--light-line : lighten($color--line, 15);
$color--dark-line  : darken($color--line, 15);

.site {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;

/*  .js-lock-scroll & {
    overflow: hidden;
  }*/
}

.site-content {
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding-bottom: 100px;
}

.button {
  display: inline-block;
  appearance: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: rem(14);
  line-height: rem(21);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-shadow: 1px 1px 2px rgba(0,0,0, 0.1);
  transition: color 150ms ease, box-shadow 150ms ease, background 300ms ease;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background: #fff;
  color: $color--blue;

  &:hover {
    color: $color--dark-blue;
    box-shadow: 1px 2px 3px rgba(0,0,0, 0.2);
  }

  &:active {
    color: #fff;
    transition: none;
    background: $color--blue;
  }
}

.button:not([class*='button--']) {
  padding: rem(7) rem(14);
}

.button--circular {
  position: relative;
  border-radius: 100%;
  padding: rem(14);

  span {
    display: block;
    height: 0;
    line-height: 0;
    margin: 50% 0 -50% 0;
    width: calc(100%);
    padding-bottom: calc(100%);
  }

  .fa {
    display: inline;
    line-height: 0;
  }
}

.nav-open {
  position: absolute;
  position: fixed;
  top: rem(14);
  left: rem(14);
}

.overlay,
.modal-wrapper {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  //height: 100%;
}

.overlay {
  z-index: 10000;
  overflow-x: hidden;
  overflow-y: scroll;

  &.js-open {
    display: block;
  }
}

.overlay__ripple {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
  border-radius: 100%;
  opacity: 0;
  transform-origin: 0 0;
  backface-visiblity: hidden;
  transform: scale(0) translate3d(-50%, -50%, 0);
  transition: opacity 300ms ease-in-out, transform 100ms cubic-bezier(0.7, 0, 0.6, 0.8);

  .js-animate-in & {
    opacity: 1;
    transform: scale(1) translate3d(-50%, -50%, 0);
  }


  .js-animate-out & {
    opacity: 1;
    // transform: scale(0) translate3d(100%, -100%, 0);
    transform: scale(0) translate3d(-50%, 0%, 0);
    // transform: scale(0) translate3d(0%, 0%, 0);
    // transform-origin: top right;
    transform-origin: center center;
    // transition: none;
    // transform: none;
  }

  .js-animate-done & {
    border-radius: 0;
    transform: none;
    transition: opacity 300ms ease-in-out;
  }

  &--hidden-left {
    transition: transform 0.5s ease !important;

    .js-animate-done & {
      transform: translateX(-100%);
    }
  }
}

.modal-wrapper {
  // z-index: 10001;
  z-index: 1000001;
  overflow: scroll-y;
  //-webkit-overflow-scrolling: touch;

  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  transition: transform 0.5s ease;

  .chrome-66 & .modal[data-modal] {
    clip-path: none !important;
  }

  .modal[data-modal] {
    clip-path: none !important;
  }

  &--hidden-left {
    transform: translateX(-100%);
  }

  .overlay:not(.js-open) + & {
    display: none;
  }

  .windows .overlay.js-animate-in.js-animate-done + & {
    overflow-y: scroll;
    // width: calc(100% + 18px);
  }

  .overlay.js-animate-in.js-animate-done + & {
    overflow-y: scroll;
  }

  &:after, &:before {
    display: block;
    content: "";
  }
}

.modal {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: none;
  opacity: 0;
  padding-top: 100px;
  transform: translateZ(0);
  transition: opacity 300ms 150ms ease-in-out, transform 300ms 150ms ease-in-out;
}


// test modal

.modal[data-modal="contactForm"] {
  opacity: 0;
  transform: scale(0.95);

  .cssclippaths & {
    transform: translateZ(0);
    -webkit-clip-path: circle(0% at 50% 50%);
    transition: opacity 300ms ease-in-out, -webkit-clip-path 300ms cubic-bezier(0.7, 0, 0.6, 0.8);
  }
}

.overlay[data-modal="contactForm"] {

  &.js-open + .modal-wrapper .modal[data-modal="contactForm"] {
      display: block;
  }

  &.js-animate-in + .modal-wrapper .modal[data-modal="contactForm"] {
      opacity: 1;
      transform: scale(1);
  }
}

.modal[data-modal] {
  .form {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.active {
    .form {
      opacity: 1;
    }
  }
}


.modal[data-modal="menu"] {
  opacity: 0;
  transform: scale(0.95);

  .cssclippaths & {
    transform: translateZ(0);
    -webkit-clip-path: circle(0% at 50% 50%);
    transition: opacity 300ms ease-in-out, -webkit-clip-path 300ms cubic-bezier(0.7, 0, 0.6, 0.8);
  }
}

.modal[data-modal="contactForm"] {
  @media all and (max-width: 450px) {
    padding-top: 30px;
  }

  .form {
    @media all and (max-width: 450px) {
      padding-top: 20px;
    }

    @media all and (max-width: 450px) and (max-height: 667px) {
      padding-top: 80px;
    }
  }
}

.overlay[data-modal="menu"] {

  &.js-open + .modal-wrapper .modal[data-modal="menu"] {
      display: block;
  }

  &.js-animate-in + .modal-wrapper .modal[data-modal="menu"] {
      opacity: 1;
      transform: scale(1);
  }
}
