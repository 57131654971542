/**
*
* SASS Variables
*
**/

/* Colors */

$colorMain: #292929;
$colorYellow: #f9b233;
$colorSuperYellow: #ffd300;
$colorOrange: #fa921f; // #f89f00
$colorBlack: #000;
$colorDarkBlue: #1c5498;
$colorNavyBlue: #161d32;
$colorGreen: #3cb778;
$colorGreen2: #0db973;
$colorBlue: #0071BB;
$colorBlue2: #004A92;
$colorBlue3: #01519b;
$colorRed: #ff3c42;
$colorRed2: #fe4a4f;
$colorLightBlue: #0171bb;
$colorLightBlue2: #0290da;
$colorSuperLightBlue: #008ad8;
$colorTurquoise: #15a9de;
$colorGrey: #989898;
$colorLightGrey: #f4f4f4;
$colorDarkGrey: #292a2a;
$colorWhite: #fff;
$colorHomePageAnimBG: #151A31;

$colors: (
    'black': $colorBlack,
    'orange': $colorOrange,
    'yellow': $colorYellow,
    'super-yellow': $colorSuperYellow,
    'white': $colorWhite,
    'navy-blue': $colorNavyBlue,
    'blue': $colorBlue,
    'blue2': $colorBlue2,
    'blue3': $colorBlue3,
    'light-blue': $colorLightBlue,
    'light-blue2': $colorLightBlue2,
    'dark-grey': $colorDarkGrey,
    'grey': $colorGrey,
    'light-grey': $colorLightGrey,
    'green': $colorGreen,
    'green2': $colorGreen2,
    'red': $colorRed,
    'red2': $colorRed2,
    'turquoise': $colorTurquoise,
    'super-light-blue': $colorSuperLightBlue,
    'hpAnimBG': $colorHomePageAnimBG
);

$bgColorsWhiteFont: (
    'orange': $colorOrange,
    'yellow': $colorYellow,
    'light-blue': $colorLightBlue,
    'dark-grey': $colorDarkGrey,
    'green': $colorGreen,
    'red': $colorRed,
    'turquoise': $colorTurquoise,
    'super-light-blue': $colorSuperLightBlue,
    'hpAnimBG': $colorHomePageAnimBG
);

$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);